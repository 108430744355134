import api from './api';

class ReglaConciliadorService {
  
  getItems(cadenaBusqueda) {
    console.log(cadenaBusqueda)
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    console.log(`/api/reglas_conciliador${cadenaBusqueda}`)
    return api.get(`/api/reglas_conciliador${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/reglas_conciliador/${id}`);
  }

  newItem(params) {
    return api.post(`/api/reglas_conciliador`, params)
  }

  editItem(params) {
    return api.put(`/api/reglas_conciliador/${params.id}`, params)
  }
}

export default new ReglaConciliadorService();