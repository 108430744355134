<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición de Paso {{ paso.nombre }} del Proceso de Conciliación
        {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="paso.nombre"
                label="Nombre del Paso"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="paso.descripcion"
                label="Descripción"
                :counter="1000"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingClasesPaso"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingClasesPaso"
                v-model="paso.clase_paso_id"
                :items="clasesPaso"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Clase de Paso"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-slider
                v-model="paso.minimo_cumplimiento"
                label="% Mínimo de cumplimiento"
                max="100"
                min="0"
                hide-details
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="paso.minimo_cumplimiento"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    readonly
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingPasos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingPasos && clasePaso != 'Fin'"
                v-model="paso.paso_siguiente_id"
                :items="pasos"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Paso siguiente"
                clearable="true"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <CButton
                v-if="!loadingSave"
                color="primary"
                class="px-4"
                @click="agregarRegla()"
                >+ Regla</CButton
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="loadingReglas">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-data-table
                v-if="!loadingReglas"
                :headers="headers"
                :items="reglas_paso"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:item.numero="{ item }">
                  {{ reglas_paso.indexOf(item) + 1 }}
                </template>

                <template v-slot:item.regla="props">
                  <v-select
                    v-model="props.item.regla"
                    :items="reglas"
                    :item-text="'nombre'"
                    :item-value="'id'"
                    attach
                    label="Regla"
                    :rules="required"
                    return-object
                  ></v-select>
                </template>

                <template v-slot:item.peso="props">
                  <v-text-field
                    v-model="props.item.peso"
                    label="Peso"
                    type="number"
                    hide-details="auto"
                    :rules="required"
                    :counter="6"
                  ></v-text-field>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="paso.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>
    <v-switch v-model="debug" :label="`Detalles para el administrador`"></v-switch>
    <pre v-if="debug">{{ reglas_paso }}</pre>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PasoProcesoConciliadorService from "@/services/pasoProcesoConciliador.service";
import ReglaService from "@/services/reglaConciliador.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";

export default {

  data() {
    return {
      paso: {
        nombre: undefined,
        descripcion: undefined,
        clase_paso_id: undefined,
        minimo_cumplimiento: 80,
        paso_siguiente_id: undefined,
        reglas: '',
        activo: true,
      },
      pasos: [],
      proceso: {
        nombre: undefined,
      },
      // minimo_cumplimiento: 80,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      reglas_paso: [],
      headers: [
        { text: "Numero", value: "numero", sortable: false },
        {
          text: "Reglas",
          value: "regla",
          sortable: false,
        },
        { text: "Peso", value: "peso", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      reglas: [],
      pasos: [],
      clasesPaso: [],
      loading: false,
      loadingPasos: false,
      loadingReglas: false,
      loadingClasesPaso: false,
      loadingSave: false,
      message: "",
      dialog: false,
      debug: false
    };
  },
  components: {
    
  },
  computed: {
    ...mapState(["permissions"]),
    clasePaso: function () {
      return (this.clasesPaso.length && this.paso.clase_paso_id)
        ? this.clasesPaso.find((x) => x.id == this.paso.clase_paso_id)
            .valor_texto
        : "";
    },
  },
  watch: {
    // minimo_cumplimiento(newVal) {
    //   this.paso.minimo_cumplimiento = newVal;
    // },
  },
  mounted() {
    if (!this.permissions.includes("Definición de proceso de conciliador"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_conciliador")) {
      this.proceso = JSON.parse(
        localStorage.getItem("datos_proceso_conciliador")
      );
    }
    if (localStorage.getItem("datos_paso_proceso_conciliador")) {
      this.paso = JSON.parse(
        localStorage.getItem("datos_paso_proceso_conciliador")
      );
      this.reglas_paso = JSON.parse(this.paso.reglas)
    }

    this.fetchClasesPaso("");
    this.fetchPasos("");
    this.fetchReglas("");

    if (!this.paso.hasOwnProperty("id")) {
      this.agregarRegla()
    }
  },
  methods: {
    save() {
      console.log(this.reglas_paso)
      let total_peso = 0
      for(let i=0; i<this.reglas_paso.length; i++)
        total_peso += this.reglas_paso[i].peso*1
      console.log(total_peso)
      if(total_peso!=100) {
        alert("La sumatoria de los pesos debe ser 100")
        return
      }
      this.loadingSave = true;
      this.paso.proceso_conciliador_id = this.proceso.id;
      this.paso.reglas = JSON.stringify(this.reglas_paso);
     
      if (this.clasePaso == "Fin") {
        this.paso.paso_siguiente_id = undefined
      }
      if (this.paso.hasOwnProperty("id")) {
        PasoProcesoConciliadorService.editItem(this.proceso.id, this.paso).then(
          (response) => {
            this.$router.push({ path: `/conciliador/procesos-conciliador/pasos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        PasoProcesoConciliadorService.newItem(this.proceso.id, this.paso).then(
          (response) => {
            this.$router.push({ path: `/conciliador/procesos-conciliador/pasos` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchReglas(cadenaBusqueda) {
      this.loadingReglas = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
        
      ReglaService.getItems(cadenaBusqueda).then(
        (response) => {
          // console.log("proceso")
          // console.log(this.proceso)
          this.reglas = response.data;
          // console.log(this.reglas)
          this.reglas = this.reglas.filter(x => x.fuente_id == this.proceso.fuente_id && x.fuente_comparacion_id == this.proceso.fuente_comparacion_id)
          // console.log("posterior al filtro")
          // console.log(this.reglas)

          this.loadingReglas = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingReglas = false;
        }
      );
    },
    fetchPasos(cadenaBusqueda) {
      this.loadingPasos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      PasoProcesoConciliadorService.getItems(this.proceso.id, cadenaBusqueda).then(
        (response) => {
          this.pasos = response.data;
          this.pasos = this.pasos.filter(x => x.id != this.paso.id)
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          this.loadingPasos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingPasos = false;
        }
      );
    },
    fetchClasesPaso(cadenaBusqueda) {
      this.loadingClasesPaso = true;
      ListaService.getClasesPaso().then(
        (response) => {
          this.clasesPaso = response.data.data;
          this.loadingClasesPaso = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingClasesPaso = false;
        }
      );
    },
    agregarRegla() {
      this.reglas_paso.push({
        regla: undefined,
        peso: ""
      });
    },
    deleteItem(regla) {
      let i = this.reglas_paso.indexOf(regla);
      this.reglas_paso.splice(i, 1);
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/conciliador/procesos-conciliador/pasos" });
    },
  },
};
</script>

<style>
</style>
